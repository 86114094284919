import React from 'react';
import { useEffect, useState } from 'react';
import { GetBonuses } from "../services/DepositService";
import { isMobile } from 'react-device-detect';
import { faCoins } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Bonus({ ProcessorName }) {
    const [ArrayBonus, setArrayBonus] = useState([]);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [ShowBonus, setShowBonus] = useState(false);
    const [Toggle, setToggle] = useState(true);
    useEffect(() => {
        localStorage.setItem('ArrayBonus', '');
        GetBonuses(localStorage.getItem('Token'), ProcessorName).then(async function (response) {
            const filtered = response.Authentication.BonusOutputList.map(item => {
                const {
                    BonusType,
                    BonusCode,
                    Description,
                    BonusID
                } = item; {
                    return {
                        BonusType,
                        BonusCode,
                        Description,
                        BonusID,
                        isChecked: false
                    };
                }
            });
            await setArrayBonus(filtered);
            localStorage.setItem('ArrayBonus', JSON.stringify(ArrayBonus));
            if (filtered != null && filtered.length > 0)
                setShowBonus(true);
            if (isMobile)
                setToggle(false);
        }).catch(function (error) {
            setShowBonus(false);
        })

    }, []);

    function ShowBonuses() {
        setToggle(!Toggle);
    }

    function ChangeSelectCheck(id) {

        for (var i = 0; i < ArrayBonus.length; i++) {
            if (ArrayBonus[i].BonusCode === id) {
                if (ArrayBonus[i].isChecked === false) {
                    ArrayBonus[i].isChecked = true;
                }
                else {
                    ArrayBonus[i].isChecked = false;
                }

            }
            else {
                ArrayBonus[i].isChecked = false;
            }
        }
        forceUpdate();
        localStorage.setItem('ArrayBonus', JSON.stringify(ArrayBonus));
    }

    return (
        <div className="content">
            <div className="row">
                &nbsp;
            </div>
            {ShowBonus && <div className="componentload">
                {isMobile && <div className="row">
                    <a className="mb-2" href="#bonuses" role="button" aria-expanded="false" aria-controls="bonuses" onClick={() => ShowBonuses()}>
                        <FontAwesomeIcon icon={faCoins} />  Tap to show Bonuses
                    </a></div>}
                {Toggle && <div id="bonuses">
                    <div className="row">
                        {ArrayBonus.map((item) => (
                            <div key={item.BonusID} className="col-md-4 col-sm-12 mb-4">
                                <div className="card bonusmaterial border-teal">
                                    <div className="ribbon-wrapper-green"><div className="ribbon-green">BONUS</div></div>
                                    <div className="card-header headergradient">
                                        <h5 className="card-title">{item.BonusCode}</h5>
                                    </div>
                                    <div className="card-body">

                                        <p className="card-text">{item.Description}</p>
                                        <div className="checkbox-animate">
                                            <label>
                                                <input checked={item.isChecked} type="checkbox" name="check" onChange={() => ChangeSelectCheck(item.BonusCode)} />
                                                <span className="input-check"></span>
                                                Click for bonus
                                            </label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}
            </div>}
        </div>
    );
}

export default Bonus;